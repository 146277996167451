import { takeEvery, join, select, all, takeLatest, put, call, fork } from 'redux-saga/effects';
import actions from '../actions';
import { selectPotentialConnections, selectSearching } from '../selectors';
import { DEFAULT_USER, DEFAULT_PASSWORD, DEFAULT_PORT, DEFAULT_USB_IP1, DEFAULT_USB_IP2 } from '../constants';
import { V2_50, V2_10, V1, UNKNOWN_MACHINE } from '../constants';
import { v4 as uuidv4 } from 'uuid';

// from https://www.techspot.com/guides/287-default-router-ip-addresses/
// sorted by most routers that use the network 
// We're assuming a subnet mask of 255.255.255.0
const commonRouterNetworks = [
  '192.168.1.',   '192.168.0.',
  '10.0.0.',      '192.168.2.',
  '192.168.10.',  '192.168.254.',
  '192.168.15.',  '192.168.100.',
  '192.168.123.', '192.168.3.',
  '10.1.1.',      '192.168.8.',
  '192.168.16.',  '192.168.20.',
  '192.168.4.',   '10.0.1.',
  '10.10.1.',     '192.168.11.',
  '10.90.90.',    '192.168.86.',
  '192.168.30.',  '192.168.62.',
  '192.168.102.', '10.1.10.',
  '192.168.168.', '192.168.50.',
  '192.168.55.',  '192.168.251.',
  '192.168.223.'
];

// Check host/port using img tag to fetch gif from server.
// This is the fastest way to check if a machine exists on the network, but
// it can have a false negative if the timeout is too short. This is used
// to scan through lists of IP addresses or other hosts, but doesn't
// guarantee a machine isn't there if it returns false.
// We'll keep track of hosts that passed this check and do a more
// thorough connection over websockets.
const quickConnectionCheck = (connection, timeout=500) => {
  return new Promise((resolve, reject) => {
    const imgAddress = "http://" + connection.host + ":" + connection.port + "/static/menu3.gif?time=" + Date.now();

    const img = document.createElement("img");
    const timeoutID = setTimeout(() => {
      img.onload = undefined;
      img.onerror = undefined;
      img.src = ""
      reject({ img })
    }, timeout);
    img.style = "display: none";

    img.onload = () => {
      clearTimeout(timeoutID);
      resolve({ img });
    };
    img.onerror = () => {
      clearTimeout(timeoutID);
      reject({ img })
    };
    document.body.appendChild(img);
    img.src = imgAddress;
  }).then( ({ img }) => {
    document.body.removeChild(img);
    return true;
  }).catch( ({ img }) => {
    document.body.removeChild(img);
    return false;
  });
};

function *checkConnectionWorker(gen) {
  const {
    value: connection,
    done
  } = gen.next();
  if(!done) {
    yield put(actions.checkingDomain(connection.host));
    const foundHost = yield call(quickConnectionCheck, connection, 500);
    if(foundHost) {
      yield put(actions.recordPotentialConnection(connection));

      yield fork(getMachineStatus, connection, { user: DEFAULT_USER, password: DEFAULT_PASSWORD });
    }

    const searching = yield select(selectSearching);
    if(searching) {
      yield call(checkConnectionWorker, gen);
    }
  }
}

// Not a saga, but a generator that produces potential connections for use by startSearching and checkConnectionWorker sagas
function *defaultConnectionGenerator() {
  yield {
    host: DEFAULT_USB_IP1,
    port: DEFAULT_PORT
  };
  yield {
    host: DEFAULT_USB_IP2,
    port: DEFAULT_PORT
  };
  for(let i = 0; i < commonRouterNetworks.length; i++) {
    const routerNetwork = commonRouterNetworks[i];
    for(let j = 0; j < 255; j++) {
      yield {
        host: routerNetwork + j,
        port: DEFAULT_PORT
      };
    }
  }
}

function *startSearching() {
  const connectionGen = defaultConnectionGenerator();
  const numWorkers = 6;
  const tasks = [];
  for(let i = 0; i < numWorkers; i++) {
    tasks.push(yield fork(checkConnectionWorker, connectionGen));
  }

  yield join(tasks);
  yield put(actions.stopSearching());
}

const getBoardRevision = (socket) => {
  return new Promise((resolve, reject) => {
    const uuid = uuidv4();
    const onMessage = (msgEvent) => {
      try {
        const msg = JSON.parse(msgEvent.data);
        if(msg.id === uuid) {
          resolve(msg.data);
        } else {
          reject();
        }
      } catch(e) {
        reject();
      }
    };
    socket.addEventListener('message', onMessage);
    socket.send(JSON.stringify({ id: uuid, command: "get", name: "board_revision" }));
  });
};

const getHighSpeedSpindle = (socket) => {
  return new Promise((resolve, reject) => {
    const uuid = uuidv4();
    const onMessage = (msgEvent) => {
      try {
        const msg = JSON.parse(msgEvent.data);
        if(msg.id === uuid) {
          const highSpeedSpindleParam = msg.data.parameters.find((param) => param.values.name === "HIGH_SPEED_SPINDLE");
          if(highSpeedSpindleParam) {
            resolve(highSpeedSpindleParam.values.value === "1");
          } else {
            resolve(false);
          }
        } else {
          reject();
        }
      } catch(e) {
        reject();
      }
    };
    socket.addEventListener('message', onMessage);
    socket.send(JSON.stringify({ id: uuid, command: "get", name: "config_item", section: "POCKETNC_FEATURES" }));
  });
};

const connectToMachine = (wsAddress, credentials) => {
  return new Promise( (resolve, reject) => {
    const socket = new WebSocket(wsAddress);
    const onOpen = () => {
      socket.send(JSON.stringify({ id: "LOGIN", user: credentials.user, password: credentials.password, date: new Date().toISOString()}));
    };

    const onMessage = (msgEvent) => {
      try {
        const msg = JSON.parse(msgEvent.data);
        if(msg.code !== "?OK") {
          socket.close();
          reject();
        } else {
          if(msg.id === "LOGIN") {
            socket.removeEventListener('open', onOpen);
            socket.removeEventListener('message', onMessage);
            socket.removeEventListener('error', onError);
            resolve(socket);
          }
        }
      } catch(e) {
        socket.close();
        reject();
      }
    };

    const onError = (err) => {
      socket.close();
      reject();
    };

    socket.addEventListener('open', onOpen);
    socket.addEventListener('message', onMessage);
    socket.addEventListener('error', onError);
  }).then((socket) => socket).catch(() => null);
};

function* getMachineStatus(connection, credentials) {
  const url = "http://" + connection.host;
  const machine = {
    url, 
    machine: UNKNOWN_MACHINE,
    loading: true
  };
  yield put(actions.addMachine(machine));

  const foundHost = yield call(quickConnectionCheck, connection, 5000);
  if(foundHost) {
    const wsAddress = "ws://" + connection.host + ":" + connection.port + "/websocket/";
    const socket = yield call(connectToMachine, wsAddress, credentials);

    if(socket) {
      const boardRevision = yield call(getBoardRevision, socket);
      const highSpeedSpindle = yield call(getHighSpeedSpindle, socket);
      socket.close();

      let machineType = UNKNOWN_MACHINE;

      if(boardRevision.startsWith("v2")) {
        if(highSpeedSpindle) {
          machineType = V2_50;
        } else {
          machineType = V2_10;
        }
      } else if(boardRevision.startsWith("v1")) {
        machineType = V1;
      }

      const machine = {
        url,
        machine: machineType
      };
      yield put(actions.addMachine(machine));
    } else {
      const machine = {
        url,
        machine: UNKNOWN_MACHINE
      };
      yield put(actions.addMachine(machine));
    }
  } else {
    yield put(actions.removeMachine(url));
  }
}

function* recordPotentialConnection() {
  const potentialConnections = yield select(selectPotentialConnections);
  window.localStorage.setItem("potentialConnections", JSON.stringify(potentialConnections));
}

function* addMachinesFromPotentialConnections() {
  const potentialConnections = yield select(selectPotentialConnections);
  for(let i = 0; i < potentialConnections.length; i++) {
    yield fork(getMachineStatus, potentialConnections[i], { user: DEFAULT_USER, password: DEFAULT_PASSWORD });
  }
}

function* refreshMachine(action) {
  const machine = action.payload;
  const host = machine.url.replace("http://", "");
  yield fork(getMachineStatus, { host, port: DEFAULT_PORT }, { user: DEFAULT_USER, password: DEFAULT_PASSWORD });
}

export default function* rootSaga() {
  return yield all([
    takeLatest(actions.startSearching, startSearching),
    takeLatest([ actions.recordPotentialConnection, actions.removePotentialConnection ], recordPotentialConnection),
    takeEvery(actions.refreshMachine, refreshMachine),
    addMachinesFromPotentialConnections()
  ])
}
