import { createActions } from 'redux-actions';

export default createActions({
  RECORD_POTENTIAL_CONNECTION: undefined,
  START_SEARCHING: undefined,
  STOP_SEARCHING: undefined,
  CHECKING_DOMAIN: undefined,
  ADD_MACHINE: undefined,
  REMOVE_MACHINE: undefined,
  REFRESH_MACHINE: undefined,
  REMOVE_POTENTIAL_CONNECTION: undefined
});
