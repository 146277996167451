export const DEFAULT_PORT = "8000";
export const DEFAULT_USER = "default";
export const DEFAULT_PASSWORD = "default";
export const DEFAULT_USB_IP1 = "192.168.7.2";
export const DEFAULT_USB_IP2 = "192.168.6.2";

export const V2_50 = "V2-50";
export const V2_10 = "V2-10";
export const V1 = "V1";
export const UNKNOWN_MACHINE = "Machine";
