import { connect } from 'react-redux';

import App from '../components/App';
import actions from '../actions';
import { UNKNOWN_MACHINE } from '../constants';

export default connect(
(state, ownProps) => ({
  machines: Object.values(state.machines),
  potentialMachines: state.potentialConnections.filter((connection) => {
    const url = "http://" + connection.host;
    return !state.machines[url];
  }).map((connection) => {
    const url = "http://" + connection.host;
    return {
      url,
      machine: UNKNOWN_MACHINE
    };
  }),
  searching: state.searching,
  checkingDomain: state.checkingDomain
}),

(dispatch, ownProps) => ({
  OnStartSearching: () => dispatch(actions.startSearching()),
  OnStopSearching: () => dispatch(actions.stopSearching()),
  OnRefreshMachine: (machine) => dispatch(actions.refreshMachine(machine)),
  OnRemoveMachine: (machine) => dispatch(actions.removePotentialConnection({ host: machine.url.replace("http://", ""), port: 8000 }))
})

)(App);
