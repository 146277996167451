export const selectSearching = (state) => {
  return state.searching;
};

export const selectPotentialConnections = (state) => {
  return state.potentialConnections;
};

export const selectDomainMapping = (state) => {
  return state.domainMapping;
}

export const selectMachines = (state) => {
  return state.machines;
}
