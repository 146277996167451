import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import logo from '../pocketnc.png';
import uiImage from '../ui.png';
import v2_10_Image from '../v2-10.webp';
import v2_50_Image from '../v2-50.webp';
import { UNKNOWN_MACHINE, V2_10, V2_50, V1 } from '../constants';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#ffffff"
    }
  }
});

const useStyles = makeStyles(theme => ({
  spacerLeft: {
    marginLeft: theme.spacing(1)
  },
  appBar: {
    backgroundColor: "#333333"
  },
  machineLink: {
  },
  networkButtons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  logo: {
    height: 32,
    marginRight: 10
  },
  machines: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4,0,2)
  },
  networkMachines: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4,0,2)
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));

const machineImages = {
  [V2_10]: v2_10_Image,
  [V2_50]: v2_50_Image,
  [V1]: uiImage,
  [UNKNOWN_MACHINE]: uiImage
};

/*
const exampleMachines = [
{
  url: 'https://192.168.7.2',
  machine: UNKNOWN_MACHINE
},
{
  url: 'https://192.168.6.2',
  machine: V2_50
},
{
  url: 'https://192.168.1.215',
  machine: V2_10
}
];
*/

function App(props) {
  const {
    machines,
    potentialMachines,
    searching,
    checkingDomain,
    OnStopSearching,
    OnStartSearching,
    OnRefreshMachine,
    OnRemoveMachine,
  } = props;
  const classes = useStyles();
  return (
  <MuiThemeProvider theme={theme}>
    <CssBaseline/>
    <AppBar className={classes.appBar} position="relative">
    <Toolbar>
    <a href="https://www.pocketnc.com" target="_blank" rel="noopener noreferrer"><img src={logo} alt="Pocket NC" className={classes.logo}/></a>
    <Typography>
      My Pocket NC Machines
    </Typography>
    </Toolbar>
    </AppBar>
    <main>
      <div className={classes.machines}>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
            My Pocket NC Machines
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
            Below are machines that we detected. 
          </Typography>
          <div className={classes.networkButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button variant="contained" color="primary" onClick={searching ? OnStopSearching : OnStartSearching}>
                    { searching ? "Stop Scanning" : "Scan For Machines" }
                  { searching ? <CircularProgress size={20} color="inherit" classes={ { root: classes.spacerLeft } }/> : null }
                  </Button>
                </Grid>
                {/*
                <Grid item>
                  <Button variant="outlined" color="primary">
                    Scan Settings
                  </Button>
                </Grid>
                */}
              </Grid>
            </div>
            <Typography variant="h6" color="textSecondary" align="center" paragraph>
              { searching ? "Checking " + checkingDomain : "" }
            </Typography>
        </Container>
      </div>
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={4}>
          {machines.map((machine,index) => {
            return (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia className={classes.cardMedia}
                           image={machineImages[machine.machine]} 
                           title={machine.machine + " at " + machine.url}/>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h6" component="h2">
                  { machine.loading ? <CircularProgress size={15}/> : null } Pocket NC {machine.machine}
                  </Typography>
                  <Typography>
                  URL: <a href={machine.url}>{machine.url}</a>
                  </Typography>
                </CardContent>
                <CardActions>
                <Button size="small" color="primary" onClick={() => OnRefreshMachine(machine)}>
                  Refresh
                </Button>
                <Button size="small" color="secondary" onClick={() => OnRemoveMachine(machine)}>
                  Remove
                </Button>
                </CardActions>
              </Card>
            </Grid>);
          })}
        </Grid>
      </Container>
      { potentialMachines.length > 0 ? <>
      <div className={classes.machines}>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
            Disconnected Machines
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
            Below are machines that we've seen before, but are not able to connect to.
          </Typography>
        </Container>
      </div>
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={4}>
          {potentialMachines.map((machine,index) => {
            return (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia className={classes.cardMedia}
                           image={machineImages[machine.machine]} 
                           title={machine.machine + " at " + machine.url}/>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h6" component="h2">
                  { machine.loading ? <CircularProgress size={15}/> : null } Pocket NC {machine.machine}
                  </Typography>
                  <Typography>
                  URL: <a href={machine.url}>{machine.url}</a>
                  </Typography>
                </CardContent>
                <CardActions>
                <Button size="small" color="primary" onClick={() => OnRefreshMachine(machine)}>
                  Refresh
                </Button>
                <Button size="small" color="secondary" onClick={() => OnRemoveMachine(machine)}>
                  Remove
                </Button>
                </CardActions>
              </Card>
            </Grid>);
          })}
        </Grid>
      </Container>
      </> : null }
    </main>
  </MuiThemeProvider>
  );
}

export default App;
