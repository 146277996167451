import { handleActions } from 'redux-actions';
import actions from '../actions';
import update from 'immutability-helper';
import deepEqual from 'deep-equal';

import { DEFAULT_PORT, DEFAULT_USB_IP1, DEFAULT_USB_IP2 } from '../constants';

let potentialConnections = {};

try {
  potentialConnections = JSON.parse(window.localStorage.getItem('potentialConnections'));
} catch(e) {
}


if(!potentialConnections) {
  potentialConnections = [
    {
      host: DEFAULT_USB_IP1,
      port: DEFAULT_PORT
    },
    {
      host: DEFAULT_USB_IP2,
      port: DEFAULT_PORT
    }
  ];
}

const defaults = { 
  potentialConnections, // connections that have been found via scanning
  machines: {}, // machines keyed by url 
  searching: false,
  checkingDomain: ""
};

export default handleActions({
  [actions.recordPotentialConnection]: (state, action) => {
    const connection = action.payload;

    if(state.potentialConnections.find((existingConnection) => deepEqual(existingConnection, connection))) {
      return state;
    } else {
      return update(state, { potentialConnections: { $push: [ connection ] } });
    }
  },
  [actions.removePotentialConnection]: (state, action) => {
    const connection = action.payload;

    const potentialConnections = state.potentialConnections.filter((existingConnection) => !deepEqual(existingConnection, connection));
    const url = "http://" + connection.host;
    const machines = {
      ...state.machines
    };
    delete machines[url];

    return update(state, { machines: { $set: machines }, potentialConnections: { $set: potentialConnections }});
  },
  [actions.addMachine]: (state, action) => {
    const machine = action.payload
    return update(state, { machines: { [machine.url]: { $set: machine } } });
  },
  [actions.removeMachine]: (state, action) => {
    const url = action.payload
    return update(state, { machines: { $unset: [ url ] } });
  },
  [actions.startSearching]: (state, action) => {
    return update(state, { searching: { $set: true } });
  },
  [actions.stopSearching]: (state, action) => {
    return update(state, { searching: { $set: false } });
  },
  [actions.checkingDomain]: (state, action) => {
    return update(state, { checkingDomain: { $set: action.payload } });
  }
}, defaults);
